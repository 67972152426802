import _ from 'lodash'
import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	IPageWillMountHandler,
	IPropsStore,
	Props,
	SiteFeatureConfigSymbol,
	pageIdSym,
	PageScrollRegistrarSymbol,
	SdkHandlersProvider,
} from '@wix/thunderbolt-symbols'
import { reportError } from '@wix/thunderbolt-commons'
import { SiteScrollBlockerSymbol, ISiteScrollBlocker } from 'feature-site-scroll-blocker'
import { IPageScrollRegistrar } from 'feature-page-scroll'
import { name, ReactLoaderForOOISymbol } from './symbols'
import { OOIComponentLoader, OOIPageConfig, OOISiteConfig, SetControllerProps } from './types'
import { createHostProps } from './hostProps'

export default withDependencies(
	[
		pageIdSym,
		named(PageFeatureConfigSymbol, name),
		named(SiteFeatureConfigSymbol, name),
		Props,
		ReactLoaderForOOISymbol,
		SiteScrollBlockerSymbol,
		PageScrollRegistrarSymbol,
	],
	(
		pageId,
		{ ooiComponents, accessibilityEnabled }: OOIPageConfig,
		{ ooiComponentsData, viewMode, formFactor }: OOISiteConfig,
		propsStore: IPropsStore,
		loader: OOIComponentLoader,
		siteScrollBlocker: ISiteScrollBlocker,
		{ registerToThrottledScroll }: IPageScrollRegistrar
	): IPageWillMountHandler & SdkHandlersProvider<{ setControllerProps: SetControllerProps }> => {
		return {
			getSdkHandlers() {
				return {
					setControllerProps(controllerCompId, controllerDataProps, functionNames, invokeFunction) {
						const props = controllerDataProps
						functionNames.forEach((functionName) =>
							_.set(props, functionName, (...args: any) => invokeFunction(functionName, args))
						)
						propsStore.update({
							[controllerCompId]: props,
						})
					},
				}
			},
			async pageWillMount() {
				await Promise.all(
					ooiComponents.map(async (compData) => {
						const hostProps = createHostProps({
							compData,
							pageId,
							accessibilityEnabled,
							formFactor,
							viewMode,
							siteScrollBlocker,
							registerToThrottledScroll,
						})

						const reactComponent = await loader
							.loadComponent(ooiComponentsData[compData.widgetId].componentUrl)
							.then((component) => {
								if (!component) {
									reportError(
										new Error('component is not exported'),
										hostProps.LazySentry,
										ooiComponentsData[compData.widgetId].sentryDsn
									)
								}
								return component
							})
							.catch((error) => {
								reportError(error, hostProps.LazySentry, ooiComponentsData[compData.widgetId].sentryDsn)
								return null
							})

						propsStore.update({
							[compData.compId]: {
								ReactComponent: reactComponent,
								host: hostProps,
							},
						})
					})
				)
			},
		}
	}
)
