import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import OOI from './ooi'
import { LifeCycle, WixCodeSdkHandlersProviderSym } from '@wix/thunderbolt-symbols'
import { ReactLoaderForOOISymbol } from './symbols'

export const page: ContainerModuleLoader = (bind, bindAll) => {
	bindAll([LifeCycle.PageWillMountHandler, WixCodeSdkHandlersProviderSym], OOI)
	if (process.env.browser) {
		bind(ReactLoaderForOOISymbol).to(require('./componentsLoaderClient').default)
	} else {
		bind(ReactLoaderForOOISymbol).to(require('./componentsLoaderSSR').default)
	}
}

export * from './types'
export * from './symbols'
